import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useI18next } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"
import Layout from "../components/Layout"

const Locucion = ({ data }) => {
  const { t } = useTranslation()
  const { language } = useI18next()

  const [titulo, setTitulo] = useState("")

  useEffect(() => {
    if (language === "es") {
      setTitulo("tituloespanol")
    } else if (language === "ca") {
      setTitulo("titulocatalan")
    } else if (language === "en") {
      setTitulo("tituloingles")
    } else if (language === "fr") {
      setTitulo("titulofrances")
    }
  }, [language])

  const locuciones = data.locuciones.nodes
  const schema = {
    "@context": t("SEO.locuciones.schema.context"),
    "@type": t("SEO.locuciones.schema.type"),
    name: t("SEO.locuciones.schema.name"),
    description: t("SEO.locuciones.schema.description"),
    url: t("SEO.locuciones.schema.url"),
    image: t("SEO.locuciones.schema.image"),
    sameAs: t("SEO.locuciones.schema.sameAs"),
    jobTitle: t("SEO.locuciones.schema.jobTitle"),
  }

  const datos = {
    title: t("SEO.locuciones.datos.title"),
    description: t("SEO.locuciones.datos.description"),
    lang: language,
  }

  return (
    <Layout schema={schema} datos={datos}>
      <section className="container max-w-7xl mt-10 sm:mt-16 px-10">
        <h1 className="border-t border-b text-center py-4 text-3xl sm:text-4xl uppercase">
          {t("locuciones")}
        </h1>
        <article className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5 ">
          {locuciones.map((locucion, index) => (
            <div
              className="flex flex-col justify-between items-center rounded bg-gris bg-opacity-5 p-4"
              key={index}
            >
              <div className="w-full flex justify-center items-center h-full">
                <h2 className="text-gris text-2xl text-center mb-2">
                  {locucion[titulo]}
                </h2>
              </div>
              <audio controls>
                <source src={locucion.audio.url} />
              </audio>
            </div>
          ))}
        </article>
      </section>
    </Layout>
  )
}

export default Locucion

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    locuciones: allStrapiPostlocucion {
      nodes {
        audio {
          url
        }
        titulocatalan
        tituloespanol
        titulofrances
        tituloingles
      }
    }
  }
`
